//import { ref } from '@vue/composition-api'
import { ref } from 'vue'
import { isNavGroupActive } from '@core/layouts/utils'

export default function useHorizontalNavMenuHeaderGroup(item) {
  // ------------------------------------------------
  // isOpen
  // ------------------------------------------------
  const isOpen = ref(false)

  const updateGroupOpen = val => {
    // eslint-disable-next-line no-use-before-define
    isOpen.value = val
  }

  // ------------------------------------------------
  // isActive
  // ------------------------------------------------

  const isOpenMenuUser = ref(false)
  const updateGroupOpenMenuUser = val => {
    // eslint-disable-next-line no-use-before-define
    isOpenMenuUser.value = val
  }

  const isOpenMenuAdmin = ref(false)
  const updateGroupOpenMenuAdmin = val => {
    // eslint-disable-next-line no-use-before-define
    isOpenMenuAdmin.value = val
  }

  // ------------------------------------------------
  // isActive
  // ------------------------------------------------
  const isActiveMenuUser = ref(false)
  const updateIsActiveMenuUser = () => {
    isActiveMenuUser.value = isNavGroupActiveMenuUser(item.children)
  }

  const isActiveMenuAdmin = ref(false)
  const updateIsActiveMenuAdmin = () => {
    isActiveMenuAdmin.value = isNavGroupActiveMenuAdmin(item.children)
  }

  const isActive = ref(false)
  const updateIsActive = () => {
    isActive.value = isNavGroupActive(item.children)
  }




  return {
    isOpen,
    isActive,
    updateGroupOpen,
    updateIsActive,
    isOpenMenuUser,
    isActiveMenuUser,
    updateGroupOpenMenuUser,
    updateIsActiveMenuUser,
    isOpenMenuAdmin,
    isActiveMenuAdmin,
    updateGroupOpenMenuAdmin,
    updateIsActiveMenuAdmin,
  }
}
