<template>
  <div class="navbar-container d-flex d-md-none content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link pl-0" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Brand Logo Mobile -->
    <b-link class="d-flex align-items-center m-auto" to="/">
      <span class="brand-logo">
        <b-img
          :src="appLogoFull"
          alt="logo"
          style="height: 26px; filter: brightness(0) invert(1)"
        />
      </span>
    </b-link>

    <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
      <dark-Toggler class="d-block" />
    </div>  -->

    <!-- User Dropdown Mobile-->
    <b-navbar-nav class="nav align-items-center ml-0">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link pr-0"
        class="dropdown-user border-right-0"
      >
        <template class="pr-0" #button-content>
          <b-avatar
            size="32"
            :src="userData ? userData.avatar : ''"
            class="rounded bg-white text-primary"
          >
            <span class="b-avatar-text">
              <div class="text-primary">
                {{
                  userData
                    ? userData.firstName.charAt(0) + userData.lastName.charAt(0)
                    : "ND"
                }}
              </div>
            </span>
          </b-avatar>
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="$router.push('/account')"
        >
          <span>
            <feather-icon size="16" icon="UserIcon" class="mr-50" />
            Mon Compte
          </span>
        </b-dropdown-item>
        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <span>
            <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
            Déconnexion
          </span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link pr-0"
        class="dropdown-user border-right-0"
      >
        <template class="pr-0" #button-content>
          <b-avatar
            size="32"
            :src="userData ? userData.avatar : ''"
            class="rounded bg-transparent border-white badge-secondary text-primary"
          >
            <span class="b-avatar-text text-primary">
              <span class="material-icons-outlined m-0 text-primary">
                domain
              </span>
              <!-- <feather-icon
                icon="KeyIcon"
                class="m-0 text-primary"
                size="24"
                variant="primary"
              /> -->
            </span>
          </b-avatar>
        </template>
        <vs-divider
          color="#cccccc"
          class="m-0 mt-1 mb-50 text-primary"
          position="left"
          v-if="workspacesListByUserLocal.length > 1"
        >
          <span class="text-primary">Changer d'environnement</span>
        </vs-divider>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          v-for="(workspace, index) of workspacesListByUserLocal"
          v-show="
            workspacesListByUserLocal.length > 1 &&
            workspace.id !== workspaceSelected.id
          "
          :key="index"
          @click="onSwitchWorkspace(workspace.id, workspace.socialReason)"
        >
          <span class="d-flex justify-content-between w-100">
            {{ workspace.socialReason }}
            <feather-icon size="16" icon="ArrowRightCircleIcon" class="mr-0" />
          </span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { $themeConfig } from "@themeConfig";
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
// import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    // DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    let userData = JSON.parse(localStorage.getItem("login"))?.login?.userInfo;
    const { appName, appLogoFull } = $themeConfig.app;

    return {
      appName,
      appLogoFull,
      userData,
    };
  },
  computed: {
    ...mapGetters([
      "isLoadingworkspacesListByUser",
      "userInfo",
      "workspaceSelected",
    ]),
    workspacesListByUserLocal() {
      let info = JSON.parse(localStorage.getItem("userInfo"));
      return info.workspaces;
    },
  },
  methods: {
    verifAdmin() {
      if (!this.userData.roles.includes("Admin")) {
        return false;
      } else {
        return true;
      }
    },
    onSwitchWorkspace(id, workspaceName) {
      let _this = this;
      this.$bvModal
        .msgBoxConfirm(
          "Attention, tous vos onglets en cours d'édition seront fermés.",
          {
            title: "Souhaitez-vous changer de workspace ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui, confirmer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then(async (value) => {
          // if (value) {
          //   this.$tabs.items.filter(elem=>{
          //     if(elem.closeOnSwitchWorkspace){
          //       _this.$tabs.close(elem.id,null)
          //     }
          //   })
          // }else{
          //   this.$tabs.reset('/')
          // }
          if (value) {
            this.workspaceName = workspaceName;
            await this.$store.dispatch("setIsGlobalLoading", true);
            await this.$tabs.reset("/");
            await this.$store
              .dispatch("setWorkspaceSelected", { workspaceIdSelected: id })
              .then(async () => {
                await this.$store.dispatch("fetchInstitutionsList", id);
                await this.$store
                  .dispatch("setWorkspaceSelected", { workspaceIdSelected: id })
                  .then(async () => {
                    let defaultInstitution = this.institutionsList.find(
                      (el) => el.isDefault
                    );
                    // if (defaultInstitution) {
                    //   await this.$store.dispatch("setInstitutionActive", defaultInstitution);
                    // } else {
                    //   await this.$store.dispatch("setInstitutionActive", this.institutionsList[0]);
                    // }
                  });
              });
            await this.$tabs.refreshAll();
            _this.$nextTick(async () => {
              await this.$store.dispatch("setIsGlobalLoading", false);
            });
          }
        });
    },
    logout() {
      this.$store.dispatch("logOut");
      this.$router.push("/login");
    },
  },
};
</script>
